import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IFiyatlandirmaNotlarKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IFiyatlandirmaNotlarKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  setFiyatlandirmaNotlarKartSave: number;
  setFiyatlandirmaNotlarKartSave_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  sbBasvuruTuruList: any[];
  sbBasvuruTuruList_dummy: any[];
  getFiyatlandirmaNotlarKartById: any[];
  getFiyatlandirmaNotlarKartById_dummy: any[];
  spGetProjeTuruByBTuruKod: any[];
  spGetProjeTuruByBTuruKod_dummy: any[];
}

export class FiyatlandirmaNotlarKart_ScreenBase extends React.PureComponent<IFiyatlandirmaNotlarKart_ScreenProps, any> {
  fiyatlandirmanotlarkart_293578_value_kuikaSelectBoxRef: React.RefObject<any>;
  fiyatlandirmanotlarkart_972106_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "8b38dd20-4697-493f-910f-9756a3354568",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 816700, PropertyName: "label", Value: "Kaydet" },
        { Id: 739677, PropertyName: "value", Value: "Başvuru Türü" },
        { Id: 293578, PropertyName: "placeholder", Value: "..." },
        { Id: 867959, PropertyName: "value", Value: "Proje Türü" },
        { Id: 972106, PropertyName: "placeholder", Value: "..." },
        { Id: 921682, PropertyName: "value", Value: "Önemli Not" },
        { Id: 682836, PropertyName: "value", Value: "Başlık" },
        { Id: 840711, PropertyName: "value", Value: "Sıra" },
        { Id: 660872, PropertyName: "value", Value: "Açıklama" },
        { Id: 613163, PropertyName: "placeholder", Value: "..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.fiyatlandirmanotlarkart_293578_value_kuikaSelectBoxRef = React.createRef();
    this.fiyatlandirmanotlarkart_972106_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      setFiyatlandirmaNotlarKartSave: 0,
      GoBack: "",
      sbBasvuruTuruList: [],
      getFiyatlandirmaNotlarKartById: [],
      spGetProjeTuruByBTuruKod: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("fiyatlandirmanotlarkart", "");
      return;
    }

    if (true) {
      await this.FiyatlandirmaNotlarKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("fiyatlandirmanotlarkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("fiyatlandirmanotlarkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.FiyatlandirmaNotlarKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      fiyatlandirmanotlarkart_293578_value:
        this.state.getFiyatlandirmaNotlarKartById?.at?.(0)?.basvuruTuruID ?? undefined,
      fiyatlandirmanotlarkart_972106_value:
        this.state.getFiyatlandirmaNotlarKartById?.at?.(0)?.projeTuruID ?? undefined,
      fiyatlandirmanotlarkart_70594_value: this.state.getFiyatlandirmaNotlarKartById?.at?.(0)?.onemli ?? undefined,
      fiyatlandirmanotlarkart_85721_value: this.state.getFiyatlandirmaNotlarKartById?.at?.(0)?.baslik ?? undefined,
      fiyatlandirmanotlarkart_577246_value: this.state.getFiyatlandirmaNotlarKartById?.at?.(0)?.orderBy ?? undefined,
      fiyatlandirmanotlarkart_613163_value: this.state.getFiyatlandirmaNotlarKartById?.at?.(0)?.aciklama ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  FiyatlandirmaNotlarKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "FiyatlandirmaNotlarKart/FiyatlandirmaNotlarKartPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbBasvuruTuruList = result?.data.sbBasvuruTuruList;

    formVars.fiyatlandirmanotlarkart_293578_value =
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].basvuruTuruID
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].basvuruTuruID
        : null;
    formVars.fiyatlandirmanotlarkart_293578_options = stateVars.sbBasvuruTuruList;
    stateVars.getFiyatlandirmaNotlarKartById = result?.data.getFiyatlandirmaNotlarKartById;
    formVars.fiyatlandirmanotlarkart_293578_value =
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].basvuruTuruID
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].basvuruTuruID
        : null;
    formVars.fiyatlandirmanotlarkart_293578_options = stateVars.sbBasvuruTuruList;
    formVars.fiyatlandirmanotlarkart_972106_value =
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].projeTuruID
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].projeTuruID
        : null;
    formVars.fiyatlandirmanotlarkart_70594_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].onemli
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].onemli
        : null
    );
    formVars.fiyatlandirmanotlarkart_85721_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].baslik
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].baslik
        : null
    );
    formVars.fiyatlandirmanotlarkart_577246_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].orderBy
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].orderBy
        : null
    );
    formVars.fiyatlandirmanotlarkart_613163_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].aciklama
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].aciklama
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatlandirmaNotlarKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatlandirmaNotlarKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.fiyatlandirmanotlarkart_293578_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].basvuruTuruID
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].basvuruTuruID
        : null
    );

    stateVars.dataSource_293578 = this.state.sbBasvuruTuruList;
    stateVars.dataSource_293578 = this.state.sbBasvuruTuruList;
    formVars.fiyatlandirmanotlarkart_972106_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].projeTuruID
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].projeTuruID
        : null
    );

    formVars.fiyatlandirmanotlarkart_70594_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].onemli
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].onemli
        : null
    );

    formVars.fiyatlandirmanotlarkart_85721_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].baslik
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].baslik
        : null
    );

    formVars.fiyatlandirmanotlarkart_577246_value = ReactSystemFunctions.value(
      this,
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].orderBy
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].orderBy
        : null
    );

    formVars.fiyatlandirmanotlarkart_613163_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFiyatlandirmaNotlarKartById?.length > 0
        ? stateVars.getFiyatlandirmaNotlarKartById[0].aciklama
        : this.state.getFiyatlandirmaNotlarKartById?.length > 0
        ? this.state.getFiyatlandirmaNotlarKartById[0].aciklama
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  FiyatlandirmaNotlarKartComponent_816700_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmanotlarkart_613163_value", "value", "", "", "")
        ),
        "string"
      ),
      Baslik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmanotlarkart_85721_checked", "value", "", "", "")
        ),
        "boolean"
      ),
      Onemli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmanotlarkart_70594_checked", "value", "", "", "")
        ),
        "boolean"
      ),
      OrderBy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "fiyatlandirmanotlarkart_577246_value", "value", "", "", "")
        ),
        "number"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmanotlarkart_972106_value",
            "value",
            "spGetProjeTuruByBTuruKod",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmanotlarkart_293578_value",
            "value",
            "sbBasvuruTuruList",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "FiyatlandirmaNotlarKart/FiyatlandirmaNotlarKartComponent_816700_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setFiyatlandirmaNotlarKartSave = result?.data.setFiyatlandirmaNotlarKartSave;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatlandirmaNotlarKartComponent_816700_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatlandirmaNotlarKartComponent_816700_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatlandirmaNotlarKartComponent_266910_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  FiyatlandirmaNotlarKartComponent_293578_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BTuruKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "fiyatlandirmanotlarkart_293578_value",
            "value",
            "sbBasvuruTuruList",
            "id",
            "kod"
          )
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "FiyatlandirmaNotlarKart/FiyatlandirmaNotlarKartComponent_293578_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetProjeTuruByBTuruKod = result?.data.spGetProjeTuruByBTuruKod;

    formVars.fiyatlandirmanotlarkart_972106_options = stateVars.spGetProjeTuruByBTuruKod;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.FiyatlandirmaNotlarKartComponent_293578_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  FiyatlandirmaNotlarKartComponent_293578_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_972106 = this.state.spGetProjeTuruByBTuruKod;
    stateVars.dataSource_972106 = this.state.spGetProjeTuruByBTuruKod;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
